body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


font-face {
    font-family: 'Open Sans';
    src: url(%PUBLIC_URL%/fonts/OpenSans-Regular.ttf);
}
