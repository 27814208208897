.snippet-box-container {
    min-height: 120px;
}

.snippet-box-container .snippet-text {
    font-family: 'Source Code Pro';
    font-size: 20px;
    letter-spacing: 0.06ex;
    line-height: 1.8em;
}

.snippet-box-container .snippet-text .char-correct {
    color: #aaa;
}

.snippet-box-container .snippet-text .char-untyped {
    color: #333;
}

.snippet-box-container .snippet-text .char-wrong {
    color: #C00;
    background-color: #ffb6c1;
}

.snippet-box-container .snippet-text .is-cursor {
    border-bottom: 2px solid #396;
}

.snippet-box-container .snippet-text .completed-text .char-was-correct {
    color: #555;
}

.snippet-box-container .snippet-text .completed-text .char-was-wrong {
    color: #555;
    /* background-color: #ffb6c1; */
    border-bottom: 2px solid #e24b62;
}
