.app-container {
    width: 720px;
    margin: 100px auto 50px auto;
}

.snippet-box-container {
    min-height: 120px;
}

.snippet-box-container .snippet-text {
    font-family: 'Source Code Pro';
    font-size: 20px;
    letter-spacing: 0.06ex;
    line-height: 1.8em;
}

.snippet-box-container .snippet-text .char-correct {
    color: #aaa;
}

.snippet-box-container .snippet-text .char-untyped {
    color: #333;
}

.snippet-box-container .snippet-text .char-wrong {
    color: #C00;
    background-color: #ffb6c1;
}

.snippet-box-container .snippet-text .is-cursor {
    border-bottom: 2px solid #396;
}

.snippet-box-container .snippet-text .completed-text .char-was-correct {
    color: #555;
}

.snippet-box-container .snippet-text .completed-text .char-was-wrong {
    color: #555;
    /* background-color: #ffb6c1; */
    border-bottom: 2px solid #e24b62;
}

.hidden-text-input-container {
    position: absolute;
    top: 0px;
    opacity: 0;
}

.progress-indicator-container {
    margin-top: 50px;
}

.progress-indicator {
    height: 5px;
    background-color: #ddd;
}

.progress-bar {
    height: 5px;
    width: 30%;
    background-color: #396;
}

.progress-bar.completed {
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-name: glow;
            animation-name: glow;
}

@-webkit-keyframes glow {
    from {
        background-color: #396;
        box-shadow: #51c78c 0px 0px 0px;
    }

    50% {
        background-color: #51c78c;
        box-shadow: #51c78c 0px 0px 3px;
    }

    to {
        background-color: #396;
        box-shadow: #51c78c 0px 0px 0px;
    }
}

@keyframes glow {
    from {
        background-color: #396;
        box-shadow: #51c78c 0px 0px 0px;
    }

    50% {
        background-color: #51c78c;
        box-shadow: #51c78c 0px 0px 3px;
    }

    to {
        background-color: #396;
        box-shadow: #51c78c 0px 0px 0px;
    }
}

.completed-stats-container {
    font-family: "Open Sans";
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    -webkit-animation-duration: 2000ms;
            animation-duration: 2000ms;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    color: #333;
}

@-webkit-keyframes fade-in {
    from { opacity: 0; }
    50% { opacity: 0.75; }
    to { opacity: 1; }
}

@keyframes fade-in {
    from { opacity: 0; }
    50% { opacity: 0.75; }
    to { opacity: 1; }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


font-face {
    font-family: 'Open Sans';
    src: url(%PUBLIC_URL%/fonts/OpenSans-Regular.ttf);
}

