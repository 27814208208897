.progress-indicator-container {
    margin-top: 50px;
}

.progress-indicator {
    height: 5px;
    background-color: #ddd;
}

.progress-bar {
    height: 5px;
    width: 30%;
    background-color: #396;
}

.progress-bar.completed {
    animation-duration: 1000ms;
    animation-name: glow;
}

@keyframes glow {
    from {
        background-color: #396;
        box-shadow: #51c78c 0px 0px 0px;
    }

    50% {
        background-color: #51c78c;
        box-shadow: #51c78c 0px 0px 3px;
    }

    to {
        background-color: #396;
        box-shadow: #51c78c 0px 0px 0px;
    }
}
